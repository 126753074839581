import { useState, useEffect } from 'react';
import { Converter } from 'opencc-js';
import logo from './images/jianfan-logo-2.webp';
import './App.css';
import { FaRegCopy } from 'react-icons/fa';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Guide from './pages/Guide';
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';

function App() {
  const [simplifiedText, setSimplifiedText] = useState('');
  const [traditionalText, setTraditionalText] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.addEventListener('click', () => toast.dismiss());
    return () => document.removeEventListener('click', () => toast.dismiss());
  }, []);

  // 创建两个转换器
  const s2tConverter = Converter({ from: 'cn', to: 'tw' });  // 简体到繁体
  const t2sConverter = Converter({ from: 'tw', to: 'cn' });  // 繁体到简体

  // 处理简体文本输入
  const handleSimplifiedChange = (e) => {
    const newText = e.target.value;
    setSimplifiedText(newText);
    // 当简体文本框输入时，自动转换为繁体
    if (newText) {
      setTraditionalText(s2tConverter(newText));
    } else {
      setTraditionalText('');
    }
  };

  // 处理繁体文本输入
  const handleTraditionalChange = (e) => {
    const newText = e.target.value;
    setTraditionalText(newText);
    // 当繁体文本框输入时，自动转换为简体
    if (newText) {
      setSimplifiedText(t2sConverter(newText));
    } else {
      setSimplifiedText('');
    }
  };

  // 添加复制功能
  const copyText = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.dismiss();
        
        const toastId = toast.success('复制成功', {
          duration: 2000,
          position: 'bottom-center',
          style: {
            background: '#333',
            color: '#fff',
            fontSize: '14px',
            padding: '8px 12px',
            borderRadius: '4px',
          },
          offset: '300px',
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        });

        // 强制 2 秒后关闭
        setTimeout(() => {
          toast.dismiss(toastId);
        }, 2000);
      })
      .catch(err => {
        toast.dismiss();
        
        const toastId = toast.error('复制失败', {
          duration: 2000,
          position: 'bottom-center',
          style: {
            background: '#333',
            color: '#fff',
            fontSize: '14px',
            padding: '8px 12px',
            borderRadius: '4px',
          },
          offset: '300px',
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        });

        // 强制 2 秒后关闭
        setTimeout(() => {
          toast.dismiss(toastId);
        }, 2000);
        
        console.error('复制失败：', err);
      });
  };

  return (
    <Router basename="/">
      <div className="App">
        <Toaster
          position="bottom-center"
          reverseOrder={false}
          containerStyle={{
            bottom: '15%',
          }}
        />
        
        <nav style={{
          width: '100%',
          height: '54px',
          backgroundColor: '#fff',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 20px',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1000
        }}>
          <div className="nav-left" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              摸鱼实验室
            </Link>
          </div>
          <div className="nav-right" style={{ marginRight: '20px' }}>
            <Link to="/guide" className="guide-link" style={{
              textDecoration: 'none',
              color: '#333',
              padding: '8px 16px',
              fontSize: '0.9em',
              whiteSpace: 'nowrap'
            }}>
              使用指南
            </Link>
          </div>
        </nav>
        
        <Routes>
          <Route path="/简繁转换" element={
            <div>
              {/* 原来的主要内容区域 */}
              <div style={{ paddingTop: '54px' }}>
                <header className="App-header" style={{
                  height: '30vh',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  backgroundColor: '#282c34',
                  marginBottom: '20px'
                }}>
                  <img 
                    src={logo} 
                    className="App-logo" 
                    alt="AI简繁互换大师" 
                    style={{
                      width: '80px',
                      height: 'auto'
                    }} 
                  />
                  <h1 style={{ 
                    fontSize: '1.8em', 
                    margin: '10px 0',
                    color: 'white'
                  }}>AI简繁换大师</h1>
                  <p className="slogan" style={{ 
                    fontSize: '1em', 
                    margin: '5px 0',
                    color: 'white'
                  }}>——准确高效 本地化用词</p>
                </header>
                
                <div className="converter-container" style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '20px',
                  padding: '20px',
                  width: '100%',
                  maxWidth: '1200px',
                  margin: '0 auto',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: 'center',
                  boxSizing: 'border-box'
                }}>
                  <div className="text-area-container" style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: isMobile ? '90%' : 'auto',
                    maxWidth: '100%'
                  }}>
                    <label>简体中文</label>
                    <div className="textarea-wrapper">
                      <textarea
                        className="simplified-box"
                        placeholder="请输入简体中文"
                        value={simplifiedText}
                        onChange={handleSimplifiedChange}
                        style={{
                          height: '260px', 
                          width: isMobile ? '100%' : '350px',
                          maxWidth: 'calc(100vw - 40px)',
                          padding: '10px',
                          borderRadius: '4px',
                          border: '1px solid #ddd',
                          boxSizing: 'border-box'
                        }}
                      />
                      <button 
                        className="copy-button"
                        onClick={() => copyText(simplifiedText)}
                      >
                        <FaRegCopy />
                      </button>
                    </div>
                  </div>

                  <div className="text-area-container" style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: isMobile ? '90%' : 'auto',
                    maxWidth: '100%'
                  }}>
                    <label>繁體中文</label>
                    <div className="textarea-wrapper">
                      <textarea
                        className="traditional-box"
                        placeholder="請輸入繁體中文"
                        value={traditionalText}
                        onChange={handleTraditionalChange}
                        style={{
                          height: '260px', 
                          width: isMobile ? '100%' : '350px',
                          maxWidth: 'calc(100vw - 40px)',
                          padding: '10px',
                          borderRadius: '4px',
                          border: '1px solid #ddd',
                          boxSizing: 'border-box'
                        }}
                      />
                      <button 
                        className="copy-button"
                        onClick={() => copyText(traditionalText)}
                      >
                        <FaRegCopy />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          } />
          <Route path="/simplified-traditional-chinese-converter" element={
            <div>
              {/* 原来的主要内容区域 */}
              <div style={{ paddingTop: '54px' }}>
                <header className="App-header" style={{
                  height: '30vh',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  backgroundColor: '#282c34',
                  marginBottom: '20px'
                }}>
                  <img 
                    src={logo} 
                    className="App-logo" 
                    alt="AI简繁互换大师" 
                    style={{
                      width: '80px',
                      height: 'auto'
                    }} 
                  />
                  <h1 style={{ 
                    fontSize: '1.8em', 
                    margin: '10px 0',
                    color: 'white'
                  }}>AI简繁换大师</h1>
                  <p className="slogan" style={{ 
                    fontSize: '1em', 
                    margin: '5px 0',
                    color: 'white'
                  }}>——准确高效 本地化用词</p>
                </header>
                
                <div className="converter-container" style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '20px',
                  padding: '20px',
                  width: '100%',
                  maxWidth: '1200px',
                  margin: '0 auto',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: 'center',
                  boxSizing: 'border-box'
                }}>
                  <div className="text-area-container" style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: isMobile ? '90%' : 'auto',
                    maxWidth: '100%'
                  }}>
                    <label>简体中文</label>
                    <div className="textarea-wrapper">
                      <textarea
                        className="simplified-box"
                        placeholder="请输入简体中文"
                        value={simplifiedText}
                        onChange={handleSimplifiedChange}
                        style={{
                          height: '260px', 
                          width: isMobile ? '100%' : '350px',
                          maxWidth: 'calc(100vw - 40px)',
                          padding: '10px',
                          borderRadius: '4px',
                          border: '1px solid #ddd',
                          boxSizing: 'border-box'
                        }}
                      />
                      <button 
                        className="copy-button"
                        onClick={() => copyText(simplifiedText)}
                      >
                        <FaRegCopy />
                      </button>
                    </div>
                  </div>

                  <div className="text-area-container" style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: isMobile ? '90%' : 'auto',
                    maxWidth: '100%'
                  }}>
                    <label>繁體中文</label>
                    <div className="textarea-wrapper">
                      <textarea
                        className="traditional-box"
                        placeholder="請輸入繁體中文"
                        value={traditionalText}
                        onChange={handleTraditionalChange}
                        style={{
                          height: '260px', 
                          width: isMobile ? '100%' : '350px',
                          maxWidth: 'calc(100vw - 40px)',
                          padding: '10px',
                          borderRadius: '4px',
                          border: '1px solid #ddd',
                          boxSizing: 'border-box'
                        }}
                      />
                      <button 
                        className="copy-button"
                        onClick={() => copyText(traditionalText)}
                      >
                        <FaRegCopy />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          } />
          <Route path="/" element={
            <div>
              {/* 原来的主要内容区域 */}
              <div style={{ paddingTop: '54px' }}>
                <header className="App-header" style={{
                  height: '30vh',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  backgroundColor: '#282c34',
                  marginBottom: '20px'
                }}>
                  <img 
                    src={logo} 
                    className="App-logo" 
                    alt="AI简繁互换大师" 
                    style={{
                      width: '80px',
                      height: 'auto'
                    }} 
                  />
                  <h1 style={{ 
                    fontSize: '1.8em', 
                    margin: '10px 0',
                    color: 'white'
                  }}>AI简繁换大师</h1>
                  <p className="slogan" style={{ 
                    fontSize: '1em', 
                    margin: '5px 0',
                    color: 'white'
                  }}>——准确高效 本地化用词</p>
                </header>
                
                <div className="converter-container" style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '20px',
                  padding: '20px',
                  width: '100%',
                  maxWidth: '1200px',
                  margin: '0 auto',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: 'center',
                  boxSizing: 'border-box'
                }}>
                  <div className="text-area-container" style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: isMobile ? '90%' : 'auto',
                    maxWidth: '100%'
                  }}>
                    <label>简体中文</label>
                    <div className="textarea-wrapper">
                      <textarea
                        className="simplified-box"
                        placeholder="请输入简体中文"
                        value={simplifiedText}
                        onChange={handleSimplifiedChange}
                        style={{
                          height: '260px', 
                          width: isMobile ? '100%' : '350px',
                          maxWidth: 'calc(100vw - 40px)',
                          padding: '10px',
                          borderRadius: '4px',
                          border: '1px solid #ddd',
                          boxSizing: 'border-box'
                        }}
                      />
                      <button 
                        className="copy-button"
                        onClick={() => copyText(simplifiedText)}
                      >
                        <FaRegCopy />
                      </button>
                    </div>
                  </div>

                  <div className="text-area-container" style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: isMobile ? '90%' : 'auto',
                    maxWidth: '100%'
                  }}>
                    <label>繁體中文</label>
                    <div className="textarea-wrapper">
                      <textarea
                        className="traditional-box"
                        placeholder="請輸入繁體中文"
                        value={traditionalText}
                        onChange={handleTraditionalChange}
                        style={{
                          height: '260px', 
                          width: isMobile ? '100%' : '350px',
                          maxWidth: 'calc(100vw - 40px)',
                          padding: '10px',
                          borderRadius: '4px',
                          border: '1px solid #ddd',
                          boxSizing: 'border-box'
                        }}
                      />
                      <button 
                        className="copy-button"
                        onClick={() => copyText(traditionalText)}
                      >
                        <FaRegCopy />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;